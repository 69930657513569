$(document).ready(function() {

	// generic year for copyright
    var d = new Date();
    $('.date-now').html(d.getFullYear());

    var s = skrollr.init({
		smoothScrolling: false,
		mobileDeceleration: 0.004
	});

    skrollr.menu.init(s, {
        animate: true,
        easing: 'outCubic',
        duration: function(currentTop, targetTop) {
            //return 1000;
            //console.log(currentTop + ', ' + targetTop);
            return Math.abs(currentTop - targetTop); // * 10;
        },
        updateUrl: true
    });

    // wow analytics addition

    $('.nav a').on('click', function() {
        //console.log('clicked');
        // get the custom title
        var element = jQuery(this);
        var title = element.html();
        var url = element.attr('href');
        // if the url starts with a # then it is an anchor tag
        if (url[0] === '#') {
            url = document.baseURI + url;
            //console.log('clicked hash');
        }
            
        // add the
        _wow.push(['trackPageView', title, url]);

    });

    var isWebkit = 'WebkitAppearance' in document.documentElement.style;
    if (isWebkit) {
	   $("body").smoothWheel();
    }

    /*
	$('a[href*=#]:not([href=#])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')  || location.hostname == this.hostname) {

        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
           if (target.length) {
           	var menuOffset = 0;
           	if($(window).width() >= 768) { menuOffset = 90; }
             $('html,body').animate({
                 scrollTop: target.offset().top - menuOffset
            }, 1000);
            return false;
        }
    }
    


    });
    */

    $('#header-nav').on('hidden.bs.collapse shown.bs.collapse', function(e){
        //console.log('toggle?');
        s.refresh();
    });

    $('.client-list').on('init', function(event, slick) {
        s.refresh();
    });

    document.addEventListener('orientationchange', function(e) {
        //alert('switch');
        window.scrollTo(0,0);
        $isotopecontainer.isotope('layout');
        $isotopecontainercase.isotope('layout');
        s.refresh();
    });
    
    $('.client-list').slick({
        arrows: false,
        centerMode: true,
        infinite: true,
        slidesToShow: 5,
        autoplay: true,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1140,
                settings: {
                    slidesToShow: 3
                }
                
            },
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 2
                }
                
            }
            
        ]
    });

    //$('.subtitling-wrap').on('init', function(event, slick) {
    //    console.log('first');
    //});
    //$('.subtitling-wrap').on('setPosition', function(event, slick) {
    //    console.log('again');
    //});


    $('.subtitling-wrap').slick({
        dots: false,
        arrows: false,
        infinite: true,
        fade: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 2000,

    });

    //$('.subtitling').on("click",function() {
    //    $('.subtitling-wrap').slick.setPosition();
    //    console.log('sub clicked');
    //})


    // need to destroy .... $isotopecontainer.isotope('destroy') .... isotope elements on phone sizes? & re-initialise on larger sizes


    var $isotopecontainer = $('.isotope');

    $isotopecontainer.isotope({
        itemSelector: '.service-item'
    });


    $('.service-item .blue-link').click(function(e) {
        e.preventDefault();

        if($(this).hasClass('subtitling')) {
            $('.subtitling-wrap').resize();
            setTimeout(function() {
                $isotopecontainer.isotope('layout');
                s.refresh();
            }, 100);
            //console.log('sub clicked');
        }

        if($(this).next('.service-copy').is(':visible')) {
            //hide stuff
            $(this).removeClass('active');
            $(this).next('.service-copy').hide();
            $isotopecontainer.isotope('layout');
        }
        else {
            //show stuff
            $(this).addClass('active');
            $(this).next('.service-copy').show();
            $isotopecontainer.isotope('layout');
        }



        s.refresh();
    });

    var $isotopecontainercase = $('.isotope-case');

    $isotopecontainercase.isotope({
        itemSelector: '.case-item'
    });


    $('.case-item .blue-link').click(function(e) {
        e.preventDefault();

        if($(this).next('.case-copy').is(':visible')) {
            //hide stuff
            $(this).removeClass('active');
            $(this).next('.case-copy').hide();
            $isotopecontainercase.isotope('layout');
        }
        else {
            //show stuff
            $(this).addClass('active');
            $(this).next('.case-copy').show();
            $isotopecontainercase.isotope('layout');
        }

        s.refresh();
    });
    
    $('.case-item iframe').wrap('<div class="iframewrap"></div>');

});

$(window).load(function() {


});
